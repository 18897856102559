import React from "react";
import { track } from "analytics/adobe";
import { PageData } from "oe-shared/analytics";

export const PageView: React.FC<PageData> = ({ name, category }) => {
  React.useEffect(() => {
    track.pageView({ name, category });
  }, [category, name]);

  return null;
};
