export enum EventType {
  VIRTUAL_PAGE_VIEW = "virtual_page_view",
  BOOK_CHECKOUT = "book_checkout",
  SEND_EVENT = "send_event",
}

export enum EventDataName {
  SIGN_IN = "sign_in",
}

export enum Provider {
  CLEVER = "Clever",
  FIRST_BOOK = "First Book",
}

export enum SiteSectionLabel {
  WEB_READER = "Web Reader",
  WEB_APP = "Web App",
  MARKETING_SITE = "Marketing Site",
  BOOK_DETAIL = "Book Detail",
  CATALOG = "Catalog",
}
