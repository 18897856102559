/**
 * FORM
 */
export const CONTACT_EMAIL = "info@openebooks.org";
export const DEV_CONTACT_EMAIL = "openebooksdev@nypl.org";

/**
 * AUTH
 */
export const CLEVER_SIGNIN_QUERY_PARAM_TOKEN = "access_token";
export const CLEVER_SIGNIN_QUERY_PARAM_IS_NEW = "is_new";
export const CLEVER_SIGNIN_QUERY_PARAM_AGE_GROUP = "age_group";
export const CLEVER_SIGNIN_QUERY_ROOT_LANE = "root_lane";
