export const pageName = {
  about: "oeb|web|about",
  acknowledge: "oeb|web|acknowledge",
  bdp: "oeb|web|bdp",
  clever: "oeb|web|signin|clever",
  contact: "oeb|web|contact",
  discover: "oeb|web|discover",
  educator: "oeb|web|educators",
  faq: "oeb|web|faq",
  firstbook: "oeb|web|signin|firstbook",
  home: "oeb|web|home",
  mybooks: "oeb|web|mybooks",
  partner: "oeb|web|partners",
  privacy: "oeb|web|privacy",
  read: "oeb|web|read",
  search: "oeb|web|discover|search",
  signin: "oeb|web|signin",
  terms: "oeb|web|terms",
};
