import { Analytics } from "oe-shared";
import { sanitizeProductItem } from "oe-shared/analytics";
export const pageView = (pageData: Analytics.PageData) => {
  const adobeDataLayer = window.adobeDataLayer || [];

  adobeDataLayer.push({
    page_name: null,
    site_section: null,
  });

  /**
   * https://blastwiki.atlassian.net/wiki/spaces/NYPL/pages/7898713056053494306/Virtual+Page+View+NYPL
   */
  adobeDataLayer.push({
    event: Analytics.EventType.VIRTUAL_PAGE_VIEW,
    page_name: pageData.name,
    site_section: pageData.category,
  });
};

export const checkout = (productListItem: Analytics.ProductListItem) => {
  // https://blastwiki.atlassian.net/wiki/spaces/NYPL/pages/7898713056053494578/Book+Checkout+NYPL
  const adobeDataLayer = window.adobeDataLayer || [];

  // Clear the data layer of previous values
  adobeDataLayer.push({
    productListItems: null,
  });

  // Push the new values
  const _productListItem = sanitizeProductItem(productListItem);
  adobeDataLayer.push({
    event: Analytics.EventType.BOOK_CHECKOUT,
    productListItems: [_productListItem],
  });
};

export const event = (eventData: Analytics.EventData) => {
  const adobeDataLayer = window.adobeDataLayer || [];

  adobeDataLayer.push({
    event_data: null,
  });

  adobeDataLayer.push({
    event: Analytics.EventType.SEND_EVENT,
    event_data: eventData,
  });
};
